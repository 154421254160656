// Custom styles for this theme

.header > .container-fluid,
.sidebar-header {
  min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
}

.header > .container-fluid + .container-fluid {
  min-height: 3rem;
}

.sidebar-brand-full {
  margin-left: 3px;
}

.sidebar-toggler {
  @include ltr-rtl("margin-left", auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-toggler {
    @include ltr-rtl("margin-right", auto);
  }
}

.footer {
  min-height: calc(3rem + 1px); // stylelint-disable-line function-disallowed-list
}
